import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

interface IProps {}

const Csr = (props: IProps) => {
  const { formatMessage: fm } = useIntl();
  const [values, setValues] = React.useState([
    {
      title: fm({ id: "csr-lines-1" }),
    },
    {
      title: fm({ id: "csr-lines-2" }),
    },
    {
      title: fm({ id: "csr-lines-3" }),
    },
    {
      title: fm({ id: "csr-lines-4" }),
    },
    {
      title: fm({ id: "csr-lines-5" }),
    },
  ]);
  return (
    <>
      <SEO title={fm({ id: "menu-social" })} />
      <Layout>
        <div>
          <div className="relative z-40">
            <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center transform scale-100">
              <StaticImage
                src="../images/bilguunzul.jpg"
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
            {/* <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60"></div> */}

            <div
              className="container relative mx-auto  h-screen flex items-end  justify-center text-white pb-48"
              data-sal="slide-up"
              data-dal-delay="1000"
              data-sal-duration={2000}
            >
              <div className="text-xl font-cool w-full px-4  md:px-32 lg:text-2xl tracking-wider leading-snug text-white flex flex-col md:flex-row md:items-center">
                <div className="pb-12 w-32 md:pr-24 md:pb-0 md:w-400">
                  <StaticImage
                    src="../images/bilguunzul_logo.png"
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <p className="md:border-l-2 md:pl-8">
                  {fm({ id: "csr-title" })}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full  min-h-screen overflow-hidden flex flex-col relative justify-center bg-white">
            <div className="bg-c1 bg-opacity-50 w-full lg:w-screen transform lg:-translate-x-3/4 h-screen  rounded-full absolute left-0 bottom-0 top-0"></div>
            <div
              className="container p-4 relative mx-auto"
              data-sal="slide-up"
              data-dal-delay="1000"
              data-sal-duration={2000}
            >
              <div className="flex flex-col lg:flex-row items-center">
                <div className="w-full lg:w-1/2 mb-20 lg:mb-0">
                  <div className="my-16 w-40">
                    <StaticImage
                      src="../images/projects/bilguunzul.png"
                      alt="bilguunzul"
                    />
                  </div>
                  <p className="font-cool text-gray-600 text-xl w-full lg:w-10/12 border-t pt-10">
                    {fm({ id: "csr-desc" })}
                  </p>
                </div>
                <div className="w-full lg:w-1/2">
                  <div className="bg-c2 absolute h-full right-0 w-full transform lg:translate-x-1/2"></div>

                  <div className="relative px-8 lg:px-16 py-10 lg:py-20">
                    <h1 className="text-4xl text-gray-700 font-cool border-b pb-10">
                      {fm({ id: "csr-lines-title" })}
                    </h1>
                    <ul className="list-disc">
                      {values?.map((v, i) => (
                        <li
                          key={`valuer-${i}`}
                          className="py-5 border-b flex space-x-10 text-2xl italic font-bold text-main font-cool text-opacity-80"
                        >
                          - {v.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Csr;
